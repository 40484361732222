@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #EAEAE5;
}
::-webkit-scrollbar-thumb {
  background: #FF5131;
  border-radius: 20px;
  
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.roboto {
  font-family: "Roboto", sans-serif;
}

.manrope {
  font-family: "Manrope", sans-serif;
}

.spanmain span{
  color: #FF5131;
}

.button{
  display: flex;
  padding: 10px 20px;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 40px;
  background: #FF5131;
}

.Dropdown-control {
  cursor: pointer;
  font-style: normal!important;
  font-size: 16px!important;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  background-color: transparent!important;
  border: none!important;
  color: #000000B2!important;
  text-transform: uppercase!important;
  padding-right:25px!important;
  
}
.Dropdown-menu {
  font-style: normal;
  font-size: 16px!important;
  max-height: 300px!important;
  
}
.Dropdown-arrow {
  border-color: #000000B2 transparent transparent!important;
  margin-top: 3px;
 
}
.is-open .Dropdown-arrow {
  border-color: transparent transparent #000000B2!important;
}

.button_main{
  display: inline-flex;
  padding: 14px 30px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 40px;
  background: #000;
}

.spanhow span {
  font-weight: 600;
}